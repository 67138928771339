import { toTypedSchema } from '@vee-validate/zod'
import { useField } from 'vee-validate'

import type { z } from 'zod'

/**
 * Serves as a wrapper around vee-validate's `useField` which helps to avoid extra boilerplate.
 *
 * @param {string} fieldName - The name of the field to validate.
 * @param {z.ZodType<any, any>} schema - The zod schema to validate against.
 * @param {Parameters<typeof useField<TValue>>[2]} options - The options to pass to `useField`.
 * @returns
 */
export function useFieldValidation<TValue = unknown>(
  fieldName: string,
  schema: z.ZodType<TValue, any>,
  options?: Parameters<typeof useField<TValue>>[2],
) {
  return useField<TValue>(fieldName, toTypedSchema(schema), options)
}

/**
 * Shorthand for that common additional option at the end of the useFieldValidation composable function.
 * This need to be placed in the third argument of the `useFieldValidation()` function!
 *
 * @param { T } initialValue - The initial value of the field.
 */
export const defaultFieldValidationSettings = <T>(initialValue: T) => ({
  syncVModel: true,
  validateOnValueUpdate: false,
  initialValue,
})
